import React, { useEffect, useState, useRef } from 'react';
import { firestoreDb, storage } from './utils/firebase';
import { collection, addDoc, query, onSnapshot, orderBy, serverTimestamp, limit, startAfter } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { ReactComponent as ImgIcon } from './images/img-icon.svg';
import ReCAPTCHA from "react-google-recaptcha";
import { ReactComponent as PlusIcon } from './images/plus-icon.svg';
import VideoPoster from './images/poster.png';
import TrashPoster from './images/trash-poster.png';
// import { Timestamp } from "firebase/firestore";
import Modal from 'react-modal';
import { ReactComponent as TrashIcon } from './images/trash-icon.svg';
import { initializeApp } from "firebase/app";
import ReactQuill from 'react-quill';
import { getFirestore, collection as getCollection, getDocs } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCLRkK7BxY1DMYUqufsbt3iYBq-o8IttY0",
    authDomain: "gis-von-ice.firebaseapp.com",
    projectId: "gis-von-ice",
    storageBucket: "gis-von-ice.appspot.com",
    messagingSenderId: "141131858780",
    appId: "1:141131858780:web:17e5f9d378900ecaba7799"
};


const scrollToNewPost = () => {
    setTimeout(() => {
        const postsContainer = document.querySelector('.posts.white-container');
        if (postsContainer) {
            postsContainer.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, 500);
};



// Feed component to handle post submission and displaying posts
const Feed = () => {
    const [loading, setLoading] = useState(true);
    const [posts, setPosts] = useState([]);
    const [text, setText] = useState("");
    const [fullName, setFullName] = useState("");
    const [images, setImages] = useState([]);
    const [imagesPreviewUrls, setImagesPreviewUrls] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [shouldPauseVideos, setShouldPauseVideos] = useState(false);
    const [newPostAdded, setNewPostAdded] = useState(false);
    const [fileInputValue, setFileInputValue] = useState();
    const fileInputRef = useRef();
    const [isIOS, setIsIOS] = useState(false);
    // initialize a new state to keep track of the current page
    const [page, setPage] = useState(0);
    const [lastVisible, setLastVisible] = useState(null); // keep track of the last visible document
    const [totalPosts, setTotalPosts] = useState(0);
    const [displayCount, setDisplayCount] = useState(10);
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);



    const [captchaValue, setCaptchaValue] = useState(null);

    const handleCaptchaResponse = (value) => {
        setCaptchaValue(value);
    };

    <ReCAPTCHA
        sitekey="6LeeFEEnAAAAADl9JILR0dDH2PbEPNPAIHDQf-SH"
        onChange={handleCaptchaResponse}
    />

    // modify your useEffect where you fetch posts
    useEffect(() => {
        let postsQuery;
        if (lastVisible) {
            postsQuery = query(collection(firestoreDb, "posts"), orderBy("timestamp", "desc"), startAfter(lastVisible), limit(10));
        } else {
            postsQuery = query(collection(firestoreDb, "posts"), orderBy("timestamp", "desc"), limit(10));
        }

        const unsubscribe = onSnapshot(postsQuery, (querySnapshot) => {
            let loadedPosts = [];
            querySnapshot.forEach((doc) => {
                loadedPosts.push(doc.data());
            });

            if (querySnapshot.docs.length > 0) {
                // update the state with the last visible document
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            }

            // instead of replacing posts, you are adding to them
            setPosts(oldPosts => [...oldPosts, ...loadedPosts]);
            setNewPostAdded(false);
        });

        return () => unsubscribe();
    }, [page]); // add page as a dependency

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch('https://jsonplaceholder.typicode.com/posts');
                const data = await response.json();
                setPosts(data);
            } catch (error) {
                console.error("Error fetching data: ", error);
                // Handle the error according to your application's needs
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        const postsQuery = query(collection(firestoreDb, "posts"), orderBy("timestamp", "desc"));
        const unsubscribe = onSnapshot(postsQuery, (querySnapshot) => {
            let loadedPosts = [];
            querySnapshot.forEach((doc) => {
                loadedPosts.push(doc.data());
            });
            setPosts(loadedPosts);
            setNewPostAdded(false);
        });

        return () => unsubscribe();
    }, []);

    useEffect(() => {
        if (/iPad|iPhone|iPod/.test(navigator.platform)) {
            setIsIOS(true);
        }
    }, []);


    const fetchMorePosts = async () => {
        const postsCollection = getCollection(db, "posts");
        // const postSnapshot = await getDocs(postsCollection);
    };

    // const handleTextChange = (e) => setText(e.target.value);
    const handleTextChange = (value) => {
        setText(value);
    };
    const handleNameChange = (e) => setFullName(e.target.value);
    const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50MB

    const handleImageChange = (e) => {
        let files = Array.from(e.target.files);

        let filesWithTypes = files.map(file => {
            if (file.size > MAX_FILE_SIZE) {
                alert("File size exceeds the limit of 50MB");
                return;
            }

            return {
                file: file,
                type: file.type.startsWith('image/') ? 'image' : 'video',
                url: URL.createObjectURL(file) // This creates a local URL for the file to preview it
            };
        }).filter(Boolean);

        setImages(oldFiles => [...oldFiles, ...filesWithTypes]);

        filesWithTypes.forEach(fileWithType => {
            setImagesPreviewUrls(oldUrls => [...oldUrls, fileWithType]);
        });

        setFileInputValue(e.target.value); // Set the file input value to the selected files
    };

    // example of updating the total number of posts
    const getPostsCount = async () => {
        const postsCollection = collection(db, "posts");
        const postSnapshot = await getDocs(postsCollection);
        setTotalPosts(postSnapshot.size);
    };

    // Add a new state variable
    const [isUploadDone, setIsUploadDone] = useState(false);

    // Modify the handleSubmit
    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        try {
            if (!captchaValue) {
                alert("Please verify the captcha");
                throw new Error("Captcha not verified");
            }

            let imageUrls = [];
            for (const media of images) {
                const storageRef = ref(storage, `images/${media.file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, media.file);

                await new Promise((resolve, reject) => {
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => { },
                        (error) => {
                            console.error("Upload error:", error);
                            reject(error);
                        },
                        async () => {
                            try {
                                let mediaUrl = await getDownloadURL(uploadTask.snapshot.ref);
                                imageUrls.push({ url: mediaUrl, type: media.type });
                                resolve();
                            } catch (error) {
                                console.log(error);
                                reject(error);
                            }
                        }
                    );
                }).catch((error) => {
                    console.error("Error during upload: ", error);
                    throw error;
                });
            }

            const newPost = {
                text: text,
                images: imageUrls,
                fullName: fullName,
                timestamp: serverTimestamp(),
            };

            if (text || imageUrls.length > 0) {
                await addDoc(collection(firestoreDb, 'posts'), newPost);
                setIsUploadDone(true); // Set isUploadDone to true after the post is added

                setText('');
                setImages([]);
                setImagesPreviewUrls([]);
                setFullName('');
            }
        } catch (error) {
            console.error('Error in form submission: ', error);
        } finally {
            setIsLoading(false);
        }
    };

    const quillRef = useRef();

    useEffect(() => {
        if (quillRef.current) {
            quillRef.current.getEditor().on('selection-change', (range, oldRange, source) => {
                const editor = quillRef.current.getEditor();
                const editorContainer = editor.container;

                if (range || editor.getText().trim().length > 0) {
                    editorContainer.classList.add('active');
                } else {
                    editorContainer.classList.remove('active');
                }
            });
        }
    }, []);


    // Add a new useEffect to scroll when isUploadDone changes
    useEffect(() => {
        if (isUploadDone) {
            scrollToNewPost();
            setIsUploadDone(false);
        }
    }, [isUploadDone]);

    useEffect(() => {
        getPostsCount();
    }, []);

    useEffect(() => {
        fetchMorePosts();
    }, [lastVisible, getPostsCount]);

    useEffect(() => {
        if (!modalIsOpen) {
            document.body.classList.remove('ReactModal__Body--open');
        }
    }, [modalIsOpen]);


    const removeImage = (index) => {
        const newImages = [...images];
        newImages.splice(index, 1);
        setImages(newImages);

        const newImagesPreviewUrls = [...imagesPreviewUrls];
        newImagesPreviewUrls.splice(index, 1);
        setImagesPreviewUrls(newImagesPreviewUrls);

        fileInputRef.current.value = null; // Reset the input value when an image is removed
    };

    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (!modalIsOpen) {
            setCurrentIndex(0);
        }
    }, [modalIsOpen]);


    const openModal = (mediaArray, currentIndex) => {
        // Store the scroll position in a variable
        window.oldScrollY = window.scrollY;

        const contentWrapper = document.getElementById('content-wrapper');
        contentWrapper.style.position = 'fixed';
        contentWrapper.style.top = `-${window.oldScrollY}px`;

        setShouldPauseVideos(true);
        setSelectedMedia(mediaArray);
        setCurrentIndex(currentIndex);  // Set the current index to the clicked media's index
        setModalIsOpen(true);
    };


    const closeModal = () => {
        const contentWrapper = document.getElementById('content-wrapper');
        contentWrapper.style.position = '';
        contentWrapper.style.top = '';

        setShouldPauseVideos(false);
        setModalIsOpen(false);
        setSelectedMedia([]);  // Reset to empty array instead of null

        // Scroll to the old position
        window.scrollTo(0, window.oldScrollY);
    };



    const MediaModal = ({ modalIsOpen, closeModal, mediaArray }) => {
        const goToNext = () => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % (mediaArray ? mediaArray.length : 1));
        };

        const goToPrevious = () => {
            setCurrentIndex((prevIndex) => {
                if (prevIndex - 1 < 0) {
                    return mediaArray ? mediaArray.length - 1 : 0;
                } else {
                    return prevIndex - 1;
                }
            });
        };

        return (
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} contentLabel="Media Modal">
                <button className="close" onClick={closeModal}><div><span></span><span></span></div></button>

                {console.log(mediaArray, currentIndex, mediaArray && mediaArray[currentIndex]?.type)}

                {mediaArray && mediaArray[currentIndex]?.type === 'image' ? (
                    <img src={mediaArray[currentIndex]?.url} alt="media content" />
                ) : (
                    <video src={mediaArray?.[currentIndex]?.url} preload="metadata" playsInline controls autoPlay onLoadedMetadata={(e) => URL.revokeObjectURL(mediaArray?.[currentIndex]?.url)} />
                )}

                {mediaArray?.length > 1 && (
                    <>
                        <button className="prev" onClick={goToPrevious}></button>
                        <button className="next" onClick={goToNext}></button>
                    </>
                )}
            </Modal>
        );
    };

    // Post component to display individual post
    const Post = ({ post, openModal, shouldPauseVideos, newPostAdded }) => {
        const date = post.timestamp?.toDate();
        const formattedDate = date ? `${date.toLocaleString('default', { month: 'short' })} ${date.getDate()}, ${date.getFullYear()}` : '';

        const videoId = `post-${post.timestamp?.seconds}`;
        const postRef = useRef(null);

        useEffect(() => {
            if (post.id === posts[0].id && postRef.current && newPostAdded) {
                postRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }, [post, posts, newPostAdded]);

        useEffect(() => {
            if (shouldPauseVideos) {
                document.querySelectorAll(`#${videoId}`).forEach(video => video.pause());
            }
        }, [shouldPauseVideos]);

        return (
            <>
                {loading ?
                    <>
                        <div className="message" ref={postRef}>
                            LOADING
                        </div>
                    </>
                    :
                    <>
                        <div className="message" ref={postRef}>
                            <div className="time-name">
                                <span className="time">{formattedDate}</span>
                                <span>/</span>
                                <span className="name">{post.fullName}</span>
                            </div>
                            <div className="text">
                                <p dangerouslySetInnerHTML={{ __html: post.text }} />
                            </div>
                            <div className="img d-flex flex-wrap">
                                {post.images && post.images.map((media, index) => (
                                    <div key={index} className="media-container" onClick={() => openModal(post.images, index)}>
                                        {media.type === 'image' ?
                                            <div className="inner-container">
                                                <img src={media.url} alt="" />
                                            </div>
                                            :
                                            <div className="video-container inner-container">
                                                <video id={videoId} className="video" src={media.url} preload="metadata" poster={isIOS ? VideoPoster : null} playsInline onLoadedMetadata={(e) => URL.revokeObjectURL(media.url)} />
                                                <div className="video-overlay" onClick={() => openModal(media)} />
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                }
            </>
        );
    };

    return (
        <div className="feed container">
            <div className='row'>
                <div className='posts white-container col-12'>
                    <h1 className='section-title'>Kveðjur</h1>
                    {posts.slice(0, displayCount).map((post, index) => (
                        <Post key={index} post={post} openModal={openModal} shouldPauseVideos={shouldPauseVideos} newPostAdded={newPostAdded} />
                    ))}
                    {posts.length > displayCount && (
                        <button className="see-more mt-4" onClick={() => setDisplayCount(displayCount + 10)}>Sjá fleiri / see more</button>
                    )}
                </div>
                <div className="white-container form-container col-12" id="write-post">
                    <form onSubmit={handleSubmit} className="d-flex flex-column">
                        <h2>Senda kveðju</h2>
                        <div className="input-container">
                            <label htmlFor="name">Full name</label>
                            <input type="text" id="name" value={fullName} onChange={handleNameChange} />
                        </div>
                        <div className='input-container'>
                            <label htmlFor="memoriam">Write your memoriam here..</label>
                            {/* <textarea id="memoriam" value={text} onChange={handleTextChange} /> */}
                            <ReactQuill ref={quillRef} id="memoriam" value={text} onChange={handleTextChange} />
                        </div>
                        <div className="file-upload">
                            <div className='label-container'>
                                {imagesPreviewUrls.length > 0 ?
                                    <>
                                        <div className="d-flex flex-wrap">
                                            {imagesPreviewUrls.map((url, index) => (
                                                <div key={index} className="preview-img uploaded-img position-relative">
                                                    {url.type === 'image' ? <img src={url.url} alt="preview" /> : <video playsInline poster={isIOS ? TrashPoster : null} src={`${url.url}#t=0.001`} alt="preview" />}
                                                    <div className="delete-icon" onClick={(e) => { e.stopPropagation(); removeImage(index); }} onTouchEnd={(e) => { e.stopPropagation(); removeImage(index); }}>
                                                        <TrashIcon style={{ pointerEvents: "none" }} />
                                                    </div>
                                                </div>
                                            ))}
                                            <div className="preview-img add-more-img d-flex align-items-center justify-content-center">
                                                <label className={`d-flex flex-column justify-content-center border-0 ${imagesPreviewUrls.length > 0 ? 'align-items-start' : 'align-items-center'}`} htmlFor="media">
                                                    <PlusIcon />
                                                    <input id="media" className="h-0" ref={fileInputRef} type="file" onChange={handleImageChange} multiple accept="image/*,video/*" />
                                                </label>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <label className={`d-flex flex-column justify-content-center no-connect ${imagesPreviewUrls.length > 0 ? 'align-items-start' : 'align-items-center'}`} htmlFor="media"></label>
                                        <ImgIcon />
                                        <div className="d-flex align-items-center justify-content-center flex-column">
                                            <span className='text-center'>Smelltu á takkann til að hlaða inn myndum</span>
                                            <div className='input-btn-container'>Hlaða inn myndum<input id="media" ref={fileInputRef} type="file" onChange={handleImageChange} multiple accept="image/*,video/*" /></div>
                                        </div>
                                    </>
                                }
                            </div>
                        </div>
                        <ReCAPTCHA
                            sitekey="6LeeFEEnAAAAADl9JILR0dDH2PbEPNPAIHDQf-SH"
                            onChange={handleCaptchaResponse}
                        />
                        <button type="submit" className="ms-auto mt-4 sub" disabled={isLoading}>{isLoading ? "Loading..." : "Senda kveðju"}</button>
                    </form>
                </div>
            </div>
            <MediaModal
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
                mediaArray={selectedMedia}
                currentIndex={currentIndex}
            />
        </div>
    );
};

export default Feed;
